import Page from '../../components/Page';
import moment from "moment"
import Table from "../../components/Table"
import { Avatar, Tag } from 'antd';

export default () => {
    return <Page title="Alle Termine">
        <Table
            columns={[
                {
                    title: "Erstellt",
                    key: "createdAt",
                    dataIndex: "createdAt",
                    readonly: true,
                    render: val => moment(val).fromNow()
                },
                {
                    title: "Termin",
                    key: "date",
                    dataIndex: "date",
                    valueType: 'dateTime',
                    render: (_, appointment) => {
                        return moment(appointment.date).format("dddd, DD. MMM YYYY HH:mm")
                    }
                },
                {
                    title: "Kundenberater",
                    key: "user",
                    dataIndex: "user",
                    readonly: true,
                    render: (_, { user }) => {
                        if(!user) return <Tag>Keiner</Tag>

                        return <div style={{ display: "flex", alignItems: "center" }}><Avatar style={{ marginRight: 8, backgroundColor: "#2d4b00" }}>{user.firstName.substring(0, 1) + user.lastName.substring(0, 1)}</Avatar>{`${user.firstName} ${user.lastName}`}</div>
                    }
                },
                {
                    title: "Interessent",
                    key: "lead",
                    dataIndex: "lead",
                    readonly: true,
                    render: (_, { lead }) => {
                        if(!lead) return <Tag>Keiner</Tag>

                        return <div>{lead.firstName} {lead.lastName} - {lead.email}</div>
                    }
                },
                {
                    title: "Adresse",
                    key: "address",
                    dataIndex: "address",
                    readonly: true,
                    render: (_, { lead }) => {
                        if(!lead) return <Tag>Keine</Tag>

                        return <div>{lead.street}, {lead.zip} {lead.city}</div>
                    }
                },
                {
                    title: "Notizen",
                    key: "notes",
                    dataIndex: "notes"
                }
            ]}
            title="Alle Termine (neuste zuerst)"
            modes={[]}
            request={async () => {
                const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/appointment/all`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    }
                })

                if(data.status !== 200) return ({
                    data: [],
                    total: 0,
                    success: false
                })

                const appointments = await data.json()

                return ({
                    data: appointments.map(appointment => ({ ...appointment, user: appointment.lead.user })),
                    total: appointments.length,
                    success: true,
                })
            }}
        />
    </Page>
}
