import Page from '../../components/Page';
import { ClockCircleOutlined, CalendarOutlined, FormOutlined, SmileOutlined } from '@ant-design/icons';
import { Calendar, Card, Col, Row, Statistic, Badge } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';

export default () => {
    const [ appointments, setAppointments ] = useState([])
    const [ leads, setLeads ] = useState([])
    const [ proposals, setProposals ] = useState([])

    useEffect(() => {
        (async () => {
            const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/appointment`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${global.BesserRasen.session.token}`
                }
            })

            if(data.status !== 200) return false

            const appointments = await data.json()

            setAppointments(appointments)
        })()
    }, [])

    useEffect(() => {
        (async () => {
            const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/lead`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${global.BesserRasen.session.token}`
                }
            })

            if(data.status !== 200) return false

            const leads = await data.json()

            setLeads(leads)
        })()
    }, [])

    useEffect(() => {
        (async () => {
            const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/proposal`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${global.BesserRasen.session.token}`
                }
            })

            if(data.status !== 200) return false

            const proposals = await data.json()

            setProposals(proposals)
        })()
    }, [])

    const getListData = value => {
        let listData;

        const date = moment(value.$d)
        const found = appointments.filter(appointment => moment(appointment.date).format("DD.MM.YYYY") === date.format("DD.MM.YYYY"))

        return found.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()).map(appointment => ({
            type: "success",
            content: `${moment(appointment.date).format("HH:mm")}: KVA für ${appointment.lead.firstName} ${appointment.lead.lastName} / ${appointment.lead.zip} ${appointment.lead.city}`
        }))
    };

    const getMonthData = (value) => {
        if (value.month() === 8) {
            return 1394;
        }
    }

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map((item) => (
                    <li key={item.content}>
                        <Badge status={item.type} text={item.content} />
                    </li>
                ))}
            </ul>
        );
    };

    return <Page noBackground title="Dashboard">
        <Row style={{ marginBottom: 16 }} gutter={16}>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="Warten auf Termin"
                        value={leads.filter(lead => lead.status === "waitingForConfirmation").length}
                        precision={0}
                        valueStyle={{ color: '#8aa178', fontSize: 32 }}
                        prefix={<ClockCircleOutlined />}
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="Anstehende Termine"
                        value={appointments.filter(appointment => moment(appointment.date).isSameOrAfter(moment())).length}
                        precision={0}
                        valueStyle={{ color: '#8aa178', fontSize: 32 }}
                        prefix={<CalendarOutlined />}
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="Warten auf Beratung"
                        value={appointments.filter(appointment => appointment.lead.status === "upcomingAppointment" && moment(appointment.date).isSameOrBefore(moment().endOf("day"))).length}
                        precision={0}
                        valueStyle={{ color: '#8aa178', fontSize: 32 }}
                        prefix={<FormOutlined />}
                    />
                </Card>
            </Col>
            <Col span={6}>
                <Card bordered={false}>
                    <Statistic
                        title="Beratene Interessenten"
                        value={proposals.length}
                        precision={0}
                        valueStyle={{ color: '#8aa178', fontSize: 32 }}
                        prefix={<SmileOutlined />}
                    />
                </Card>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Card bordered={false}>
                    <Calendar cellRender={(current, info) => {
                        if (info.type === 'date') return dateCellRender(current);
                        if (info.type === 'month') return null
                        return info.originNode;
                    }} />
                </Card>
            </Col>
        </Row>
    </Page>
}
