import Page from '../../components/Page';
import { Avatar, Tag } from 'antd';
import moment from "moment"
import Table from "../../components/Table"

export default () => {
    return <Page title="Alle Interessenten">
        <Table
            columns={[
                {
                    title: "Erstellt",
                    key: "createdAt",
                    dataIndex: "createdAt",
                    readonly: true,
                    render: val => moment(val).fromNow()
                },
                {
                    title: "Kundenberater",
                    key: "userId",
                    dataIndex: "userId",
                    valueType: 'select',
                    request: async () => {
                        const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/user/all`, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${global.BesserRasen.session.token}`
                            }
                        })

                        if(data.status !== 200) return [
                            {
                                value: null,
                                label: "Niemand"
                            }
                        ]

                        const users = await data.json()

                        return [
                            {
                                value: null,
                                label: "Niemand"
                            },
                            ...users.map(user => ({
                                value: user.id,
                                label: `${user.firstName} ${user.lastName}`
                            }))
                        ]
                    },
                    render: (_, { user }) => {
                        if(!user) return <Tag>Keiner</Tag>

                        return <div style={{ display: "flex", alignItems: "center" }}><Avatar style={{ marginRight: 8, backgroundColor: "#2d4b00" }}>{user.firstName.substring(0, 1) + user.lastName.substring(0, 1)}</Avatar>{`${user.firstName} ${user.lastName}`}</div>
                    }
                },
                {
                    title: "Status",
                    key: "status",
                    dataIndex: "status",
                    readonly: true,
                    render: status => <Tag color={status === "waitingForConfirmation" ? "orange" : (status === "upcomingAppointment" ? "blue" : "green")}>{status === "waitingForConfirmation" ? "Warte auf Termin" : (status === "upcomingAppointment" ? "Anstehender Termin" : "KVA unterbreitet")}</Tag>
                },
                {
                    title: "Wunschtag(-e)",
                    key: "day",
                    dataIndex: "day"
                },
                {
                    title: "Wunschuhrzeit",
                    key: "time",
                    dataIndex: "time"
                },
                {
                    title: "Vorname",
                    key: "firstName",
                    dataIndex: "firstName"
                },
                {
                    title: "Nachname",
                    key: "lastName",
                    dataIndex: "lastName"
                },
                {
                    title: "Gartengröße",
                    key: "size",
                    dataIndex: "size",
                    render: value => `${value} m²`
                },
                {
                    title: "Straße",
                    key: "street",
                    dataIndex: "street"
                },
                {
                    title: "PLZ",
                    key: "zip",
                    dataIndex: "zip"
                },
                {
                    title: "Ort",
                    key: "city",
                    dataIndex: "city"
                },
                {
                    title: "Telefon",
                    key: "phone",
                    dataIndex: "phone"
                },
                {
                    title: "E-Mail",
                    key: "email",
                    dataIndex: "email"
                },
                {
                    title: "Produkt",
                    key: "category",
                    dataIndex: "category"
                }
            ]}
            modes={[ "edit" ]}
            title="Alle Interessenten (neuste zuerst)"
            onEdit={async lead => {
                const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/lead`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    },
                    body: JSON.stringify({
                        id: lead.id,
                        userId: lead.userId === 0 ? null : lead.userId,
                        firstName: lead.firstName,
                        lastName: lead.lastName,
                        size: lead.size,
                        day: lead.day,
                        time: lead.time,
                        street: lead.street,
                        zip: lead.zip,
                        city: lead.city,
                        phone: lead.phone,
                        email: lead.email,
                        category: lead.category
                    })
                })

                return result.status === 200
            }}
            request={async () => {
                const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/lead/all`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    }
                })

                if(data.status !== 200) return ({
                    data: [],
                    total: 0,
                    success: false
                })

                const leads = await data.json()

                return ({
                    data: leads.map(lead => ({ ...lead, userId: lead.user ? lead.user.id : null })),
                    total: leads.length,
                    success: true,
                })
            }}
        />
    </Page>
}
