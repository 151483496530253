import styled from "styled-components"
import { Card, Input, Button, Form, message } from "antd"
import {useEffect, useState} from "react"
import { LoginOutlined } from '@ant-design/icons';
import Cookies from "js-cookie"
import {useSearchParams} from "react-router-dom";
import {SyncLoader} from "react-spinners";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #eee;
`

export default () => {
    const [ email, setEmail ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ partnerLoading, setPartnerLoading ] = useState(false)
    const [ mode, setMode ] = useState("login")
    const [ firstName, setFirstName ] = useState(null)
    const [ lastName, setLastName ] = useState(null)
    const [ userId, setUserId ] = useState(null)
    const [ token, setToken ] = useState(null)

    const [messageApi, contextHolder] = message.useMessage();

    const [ searchParams ] = useSearchParams()

    const partner = searchParams.get("partner")
    const loginToken = searchParams.get("token")

    useEffect(() => {
        if(partner && loginToken) {
            setPartnerLoading(true)

            fetch(`${global.BesserRasen.backendUrl}/api/v1/auth/confirm-login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: partner,
                    token: loginToken
                })
            }).then(async data => {
                if(data.status === 200) {
                    //Login was successful
                    global.BesserRasen.session = await data.json()

                    Cookies.set('session', JSON.stringify(global.BesserRasen.session), { expires: 365, domain: process.env.NODE_ENV === "development" ? "localhost" : "kb.besserrasen.de" })

                    window.open("/", "_self")
                } else {
                    //Login failed
                    messageApi.open({
                        type: 'error',
                        content: "Die Anmeldung ist fehlgeschlagen. Sie werden weitergeleitet...",
                    });

                    setTimeout(() => {
                        window.location.href = "https://besserrasen.de/login"
                    }, 2000)
                }
            })
        }
    }, [partner, loginToken])

    if(partner) return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#eee", width: "100vw", height: "100vh" }}>
        <SyncLoader size={24} color="#2d4b00" />
        {contextHolder}
    </div>

    return <Container>
        {contextHolder}
        <img style={{ maxWidth: 200, marginBottom: 64 }} src="https://besserrasen.de/img/br_logo_color.png" />
        <Card title="Beim BR-Kundenberaterportal anmelden">
            <Form layout="vertical" onFinish={() => {
                setLoading(true)

                if(mode === "login") {
                    fetch(`${global.BesserRasen.backendUrl}/api/v1/auth/attempt-login`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            email: `${email}@besserrasen.de`
                        })
                    }).then(async data => {
                        console.log(data)
                        setLoading(false)

                        if(data.status === 404) {
                            //User was not found
                            setMode("register")
                        } else if(data.status === 200) {
                            //User was found
                            const userId = await data.text()

                            setMode("token")
                            setUserId(userId)

                            messageApi.open({
                                type: 'success',
                                content: `Bitte prüfen Sie Ihre E-Mails und geben Sie den soeben versandten Code unten ein.`,
                            });
                        }
                    })
                } else if(mode === "register") {
                    fetch(`${global.BesserRasen.backendUrl}/api/v1/auth/register`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            email: `${email}@besserrasen.de`,
                            firstName,
                            lastName
                        })
                    }).then(async data => {
                        if(data.status === 201) {
                            //User was created
                            const user = await data.json()
                            setUserId(user.id)
                            setMode("token")

                            messageApi.open({
                                type: 'success',
                                content: `Die Registrierung war erfolgreich. Bitte prüfen Sie nun Ihre E-Mails und geben Sie den soeben versandten Code unten ein.`,
                            });
                        } else {
                            //Registration failed
                            messageApi.open({
                                type: 'error',
                                content: `Die Registrierung ist fehlgeschlagen (${data.status}).`,
                            });
                        }

                        setLoading(false)
                    })
                } else if(mode === "token") {
                    fetch(`${global.BesserRasen.backendUrl}/api/v1/auth/confirm-login`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            id: userId,
                            token
                        })
                    }).then(async data => {
                        if(data.status === 200) {
                            //Login was successful
                            global.BesserRasen.session = await data.json()

                            Cookies.set('session', JSON.stringify(global.BesserRasen.session), { expires: 365, domain: process.env.NODE_ENV === "development" ? "localhost" : "kb.besserrasen.de" })

                            window.open("/", "_self")
                        } else {
                            //Login failed
                            messageApi.open({
                                type: 'error',
                                content: "Die Anmeldung ist fehlgeschlagen. Bitte überprüfen Sie Ihren Code und versuchen Sie es erneut.",
                            });

                            setLoading(false)
                        }
                    })
                }
            }}>
                {mode === "register" && <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item label="Vorname">
                        <Input readOnly={loading} spellCheck={false} value={firstName} onChange={e => setFirstName((e.target.value || "").trim())} size="large" placeholder="Max" />
                    </Form.Item>
                    <Form.Item label="Nachname">
                        <Input readOnly={loading} spellCheck={false} value={lastName} onChange={e => setLastName((e.target.value || "").trim())} size="large" placeholder="Mustermann" />
                    </Form.Item>
                </Form.Item>}
                <Form.Item label="E-Mail-Adresse">
                    <Input disabled={mode === "register" || mode === "token"} readOnly={loading} spellCheck={false} value={email} onChange={e => setEmail((e.target.value || "").toLowerCase().replace(/[^a-z.]/g, ''))} addonAfter="@besserrasen.de" size="large" placeholder="mustermann" />
                </Form.Item>
                {mode === "token" && <Form.Item label="Code aus E-Mail">
                    <Input readOnly={loading} spellCheck={false} value={token} onChange={e => setToken((e.target.value || "").trim())} size="large" placeholder="ABC123" />
                </Form.Item>}
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button icon={<LoginOutlined />} loading={loading} htmlType="submit" disabled={(mode === "login" && !email) || (mode === "register" && (!firstName || !lastName))} block type="primary" size="large">{mode === "register" ? "Registrieren" :  "Anmelden"}</Button>
                </Form.Item>
            </Form>
        </Card>
    </Container>
}
