import Page from '../../components/Page';
import { Modal, Tag } from 'antd';
import moment from "moment"
import Table from "../../components/Table"
import { useState } from 'react';

export default () => {
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ leadId, setLeadId ] = useState(null)
    const [ refresh, setRefresh ] = useState()

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return <Page title="Interessenten">
        <Table
            refresh={setRefresh}
            columns={[
                {
                    title: "Erstellt",
                    key: "createdAt",
                    dataIndex: "createdAt",
                    render: val => moment(val).fromNow(),
                    readonly: true
                },
                {
                    title: "Status",
                    key: "status",
                    dataIndex: "status",
                    readonly: true,
                    render: status => <Tag color={status === "waitingForConfirmation" ? "orange" : (status === "upcomingAppointment" ? "blue" : "green")}>{status === "waitingForConfirmation" ? "Warte auf Termin" : (status === "upcomingAppointment" ? "Anstehender Termin" : "KVA unterbreitet")}</Tag>
                },
                {
                    title: "Wunschtag(-e)",
                    key: "day",
                    dataIndex: "day",
                    readonly: true
                },
                {
                    title: "Wunschuhrzeit",
                    key: "time",
                    dataIndex: "time",
                    readonly: true
                },
                {
                    title: "Vorname",
                    key: "firstName",
                    dataIndex: "firstName"
                },
                {
                    title: "Nachname",
                    key: "lastName",
                    dataIndex: "lastName"
                },
                {
                    title: "Gartengröße",
                    key: "size",
                    dataIndex: "size",
                    render: value => `${value} m²`
                },
                {
                    title: "Straße",
                    key: "street",
                    dataIndex: "street"
                },
                {
                    title: "PLZ",
                    key: "zip",
                    dataIndex: "zip",
                    readonly: true
                },
                {
                    title: "Ort",
                    key: "city",
                    dataIndex: "city"
                },
                {
                    title: "Telefon",
                    key: "phone",
                    dataIndex: "phone"
                },
                {
                    title: "E-Mail",
                    key: "email",
                    dataIndex: "email",
                    readonly: true
                },
                {
                    title: "Produkt",
                    key: "category",
                    dataIndex: "category"
                },
                {
                    title: "Aktionen",
                    readonly: true,
                    dataIndex: "actions",
                    key: "actions",
                    render: (_, lead) => {
                        if(lead.status === "proposed") return null

                        return <><a onClick={() => {
                            setLeadId(lead.id)
                            setIsModalOpen(true)
                        }}>Termine</a></>
                    }
                }
            ]}
            modes={[ "edit" ]}
            title="Interessenten (neuste zuerst)"
            onEdit={async lead => {
                const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/lead`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    },
                    body: JSON.stringify({
                        id: lead.id,
                        category: lead.category,
                        phone: lead.phone,
                        city: lead.city,
                        street: lead.street,
                        size: lead.size,
                        firstName: lead.firstName,
                        lastName: lead.lastName
                    })
                })

                return result.status === 200
            }}
            request={async () => {
                const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/lead`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    }
                })

                if(data.status !== 200) return ({
                    data: [],
                    total: 0,
                    success: false
                })

                const leads = await data.json()

                return ({
                    data: leads,
                    total: leads.length,
                    success: true,
                })
            }}
        />
        <Modal width={1280} title="Termine verwalten" open={isModalOpen} onOk={handleOk} footer={null} onCancel={handleCancel}>
            {leadId && <Table
                key={leadId}
                columns={[
                    {
                        title: "Erstellt",
                        key: "createdAt",
                        dataIndex: "createdAt",
                        readonly: true,
                        render: val => moment(val).fromNow()
                    },
                    {
                        title: "Termin",
                        key: "date",
                        dataIndex: "date",
                        valueType: 'dateTime',
                        render: (_, data) => moment(data.date).format("dddd, DD. MMM YYYY HH:mm")
                    },
                    {
                        title: "Adresse",
                        key: "address",
                        dataIndex: "address",
                        readonly: true,
                        render: (_, { lead }) => {
                            if(!lead) return <Tag>Keine</Tag>

                            return <div>{lead.street}, {lead.zip} {lead.city}</div>
                        }
                    },
                    {
                        title: "Notizen",
                        key: "notes",
                        dataIndex: "notes"
                    }
                ]}
                modes={[ "delete", "edit", "create" ]}
                onCreate={async appointment => {
                    const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/appointment`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                        },
                        body: JSON.stringify({
                            leadId,
                            date: appointment.date,
                            notes: appointment.notes
                        })
                    })

                    refresh()

                    return result.status === 201
                }}
                onDelete={async appointment => {
                    const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/appointment`, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                        },
                        body: JSON.stringify({
                            id: appointment.id
                        })
                    })

                    refresh()

                    return result.status === 200
                }}
                onEdit={async appointment => {
                    const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/appointment`, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                        },
                        body: JSON.stringify({
                            id: appointment.id,
                            notes: appointment.notes,
                            date: appointment.date
                        })
                    })

                    return result.status === 200
                }}
                request={async () => {
                    const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/appointment?leadId=${leadId}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                        }
                    })

                    if(data.status !== 200) return ({
                        data: [],
                        total: 0,
                        success: false
                    })

                    const appointments = await data.json()

                    return ({
                        data: appointments,
                        total: appointments.length,
                        success: true,
                    })
                }}
            />}
        </Modal>
    </Page>
}
