import Page from '../../components/Page';
import {
    Col,
    Form,
    Upload,
    Image,
    InputNumber,
    Modal,
    Row,
    Select,
    Tag,
    Button,
    Input,
    Divider,
    message,
    Spin,
} from 'antd';
import moment from "moment"
import Table from "../../components/Table"
import { useState } from 'react';
import { InfoCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const getBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

export default () => {
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ appointment, setAppointment ] = useState(null)
    const [ refresh, setRefresh ] = useState()
    const [ kvaRefresh, setKVARefresh ] = useState()

    const [ isPictureModalOpen, setIsPictureModalOpen ] = useState(false)
    const [ imagePreviews, setImagePreviews ] = useState([])

    const [ loading, setLoading ] = useState(false)

    // Form fields

    const [ areaForm, setAreaForm ] = useState("rectangle")
    const [ areaType, setAreaType ] = useState("garden")
    const [ subsoil, setSubsoil ] = useState("gardenTurf")
    const [ access, setAccess ] = useState("easy")
    const [ phone, setPhone ] = useState(null)
    const [ product, setProduct ] = useState("RASEN-3")
    const [ usage, setUsage ] = useState([ "optical" ])
    const [ installationHeight, setInstallationHeight ] = useState(0)
    const [ width, setWidth ] = useState(0)
    const [ depth, setDepth ] = useState(0)
    const [ size, setSize ] = useState(0)

    const [ imagePreviewsLoading, setImagePreviewsLoading ] = useState(false)

    // Form fields end

    const showModal = () => {
        setIsModalOpen(true);

        setLoading(false)
        setFileList([])
        setFileIds([])
        setSize(0)
        setDepth(0)
        setWidth(0)
        setInstallationHeight(0)
        setUsage([ "optical" ])
        setProduct("RASEN-3")
        setAccess("easy")
        setSubsoil("gardenTurf")
        setAreaType("garden")
        setAreaForm("rectangle")
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [messageApi, contextHolder] = message.useMessage();

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [fileIds, setFileIds] = useState([]);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || (file.preview));
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) =>
        setFileList(newFileList);

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Hochladen</div>
        </button>
    );

    console.log(fileList)

    return <Page title="Beratungen" rightContent={<p style={{ fontSize: 16 }}><InfoCircleOutlined style={{ color: "#2d4b00" }} /> Auf dieser Seite werden alle heutigen oder vergangenen Termine mit fehlender Beratung angezeigt.</p>}>
        {contextHolder}
        <Table
            refresh={setRefresh}
            columns={[
                {
                    title: "Termin",
                    key: "date",
                    dataIndex: "date",
                    render: (_, appointment) => {
                        if(moment().format("DD.MM.YYYY") === moment(appointment.date).format("DD.MM.YYYY")) return <><Tag color="green">Heute</Tag>{moment(appointment.date).format("dddd, DD. MMM YYYY HH:mm")}</>
                        if(moment().isAfter(moment(appointment.date))) return <b style={{ color: "#e67e22" }}><ExclamationCircleOutlined /> {moment(appointment.date).format("dddd, DD. MMM YYYY HH:mm")}</b>

                        return moment(appointment.date).format("dddd, DD. MMM YYYY HH:mm")
                    }
                },
                {
                    title: "Interessent",
                    key: "lead",
                    dataIndex: "lead",
                    render: (_, { lead }) => {
                        if(!lead) return <Tag>Keiner</Tag>

                        return <div>{lead.firstName} {lead.lastName} - {lead.email}</div>
                    }
                },
                {
                    title: "Telefon",
                    key: "phone",
                    dataIndex: "phone",
                    render: (_, { lead }) => {
                        if(!lead) return <Tag>Keiner</Tag>

                        return <div>{lead.phone}</div>
                    }
                },
                {
                    title: "Adresse",
                    key: "address",
                    dataIndex: "address",
                    render: (_, { lead }) => {
                        if(!lead) return <Tag>Keine</Tag>

                        return <div>{lead.street}, {lead.zip} {lead.city}</div>
                    }
                },
                {
                    title: "Notizen",
                    key: "notes",
                    dataIndex: "notes"
                },
                {
                    title: "Produkt",
                    key: "category",
                    dataIndex: "category",
                    render: (_, { lead }) => {
                        if(!lead) return <Tag>Keins</Tag>

                        return <Tag>{lead.category}</Tag>
                    }
                },
                {
                    title: "Aktionen",
                    key: "actions",
                    render: (_, appointment) => {
                        return <><a onClick={() => {
                            setAppointment(appointment)
                            setPhone(appointment.lead.phone)
                            showModal()
                        }}>Beratung starten</a></>
                    }
                }
            ]}
            modes={[]}
            title="Termine mit fehlender Beratung"
            request={async () => {
                const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/appointment`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    }
                })

                if(data.status !== 200) return ({
                    data: [],
                    total: 0,
                    success: false
                })

                const appointments = await data.json()

                return ({
                    data: appointments.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()).filter(appointment => appointment.lead.status === "upcomingAppointment" && moment(appointment.date).isSameOrBefore(moment().endOf("day"))),
                    total: appointments.length,
                    success: true,
                })
            }}
        />
        <div style={{ marginTop: 24 }}>
            <Table
                refresh={setKVARefresh}
                columns={[
                    {
                        title: "Erstellt",
                        key: "createdAt",
                        dataIndex: "createdAt",
                        render: (_, proposal) => {
                            return moment(proposal.createdAt).format("dddd, DD. MMM YYYY HH:mm")
                        }
                    },
                    {
                        title: "Interessent",
                        key: "lead",
                        dataIndex: "lead",
                        render: (_, { appointment }) => {
                            const { lead } = appointment

                            if(!lead) return <Tag>Keiner</Tag>

                            return <div>{lead.firstName} {lead.lastName} - {lead.email}</div>
                        }
                    },
                    {
                        title: "Telefon",
                        key: "phone",
                        dataIndex: "phone",
                        render: (_, { phone, appointment }) => {
                            const { lead } = appointment

                            if(!lead) return <Tag>Keiner</Tag>

                            return <div>{phone || lead.phone}</div>
                        }
                    },
                    {
                        title: "Adresse",
                        key: "address",
                        dataIndex: "address",
                        render: (_, { appointment }) => {
                            const { lead } = appointment

                            return <div>{lead.street}, {lead.zip} {lead.city}</div>
                        }
                    },
                    {
                        title: "Produkt",
                        key: "product",
                        dataIndex: "product"
                    },
                    {
                        title: "Fläche",
                        key: "size",
                        dataIndex: "size",
                        render: (_, { areaForm, size, width, depth }) => {
                            return <div>{areaForm === "rectangle" ? (width * depth) : size} m²</div>
                        }
                    },
                    {
                        title: "Aktionen",
                        key: "actions",
                        dataIndex: "actions",
                        render: (_, proposal) => {
                            return <div style={{ display: "flex", gap: 16 }}>
                                {proposal.images.length > 0 && <a onClick={async () => {
                                setIsPictureModalOpen(true)
                                setImagePreviews([])
                                setImagePreviewsLoading(true)

                                Promise.all(proposal.images.map(async image => {
                                    return new Promise(async resolve => {
                                        const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/proposal/image/${image.id}`, {
                                            method: "GET",
                                            headers: {
                                                "Authorization": `Bearer ${global.BesserRasen.session.token}`
                                            }
                                        })

                                        const blob = await data.blob()

                                        const reader = new FileReader()

                                        reader.onload = function() {
                                            resolve(this.result)
                                        }

                                        reader.readAsDataURL(blob)
                                    })
                                })).then(images => {
                                    setImagePreviewsLoading(false)
                                    setImagePreviews(images)
                                })
                            }}>Bilder anzeigen ({proposal.images.length})</a>}</div>
                        }
                    }
                ]}
                modes={[]}
                title="Beratene Interessenten"
                request={async () => {
                    const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/proposal`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                        }
                    })

                    if(data.status !== 200) return ({
                        data: [],
                        total: 0,
                        success: false
                    })

                    const proposals = await data.json()

                    return ({
                        data: proposals.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()),
                        total: proposals.length,
                        success: true,
                    })
                }}
            />
        </div>
        <Modal width={720} title="Bilder anzeigen" open={isPictureModalOpen} onCancel={() => setIsPictureModalOpen(false)} footer={null}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: 16 }}>
                {imagePreviews.map((imageUrl, index) => <Image key={index} src={imageUrl} height={150} />)}
            </div>
            {imagePreviewsLoading && <div style={{ width: "100%", height: 150, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin />
            </div>}
        </Modal>
        <Modal width={720} title="Beratung" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} maskClosable={false} footer={[
            <Button key="back" onClick={() => setIsModalOpen(false)}>Abbrechen</Button>,
            <Button disabled={(areaForm === "rectangle" && (width === 0 || depth === 0)) || (areaForm !== "rectangle" && size === 0) || (usage.length === 0) || (fileList.find(file => file.status !== "done"))} key="submit" type="primary" loading={loading} onClick={async () => {
                setLoading(true)

                const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/proposal`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    },
                    body: JSON.stringify({
                        width,
                        depth,
                        size,
                        installationHeight,
                        phone,
                        appointmentId: appointment.id,
                        areaForm,
                        areaType,
                        usage,
                        subsoil,
                        access,
                        product,
                        images: fileIds.filter(file => !!fileList.find(fl => fl.uid === Object.values(file)[0])).map(file => Object.keys(file)[0])
                    })
                })

                if(data.status !== 201) return messageApi.open({
                    type: 'error',
                    content: "Die Beratungsaufnahme konnte nicht erzeugt werden. Bitte wenden Sie sich an Ihren Administrator."
                });

                setIsModalOpen(false)

                refresh()
                kvaRefresh()

                return messageApi.open({
                    type: 'success',
                    content: "Die Beratungsaufnahme wurde erfolgreich erzeugt und an den Kunden versandt."
                });
            }}>Beratung abschließen</Button>,
        ]}>
            <Form layout="vertical" disabled={loading}>
                <Divider>Kunde</Divider>
                {appointment && <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Name">
                            <Input
                                disabled
                                value={`${appointment.lead.firstName} ${appointment.lead.lastName}`}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="E-Mail">
                            <Input
                                disabled
                                value={appointment.lead.email}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Wunschprodukt">
                            <Input
                                disabled
                                value={appointment.lead.category}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>}{appointment && <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Telefon / Mobilfunknr.">
                            <Input
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item label="Adresse">
                            <Input
                                disabled
                                value={`${appointment.lead.street}, ${appointment.lead.zip} ${appointment.lead.city}`}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>}
                <Divider>Fläche</Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Flächenform" required>
                            <Select
                                value={areaForm}
                                onChange={setAreaForm}
                                options={[
                                    { value: "rectangle", label: "Rechteck" },
                                    { value: "lshape", label: "L-Form" },
                                    { value: "circle", label: "Kreis" }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Länge" required>
                            <InputNumber
                                min={0}
                                disabled={areaForm !== "rectangle" || loading}
                                value={areaForm === "rectangle" ? depth : null}
                                style={{ width: '100%' }}
                                addonAfter="Meter"
                                onChange={setDepth}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Breite" required>
                            <InputNumber
                                min={0}
                                disabled={areaForm !== "rectangle" || loading}
                                value={areaForm === "rectangle" ? width : null}
                                style={{ width: '100%' }}
                                addonAfter="Meter"
                                onChange={setWidth}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Fläche" required>
                            <InputNumber
                                min={0}
                                disabled={areaForm === "rectangle"}
                                value={areaForm === "rectangle" ? width * depth : size}
                                style={{ width: '100%' }}
                                addonAfter="m²"
                                onChange={setSize}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Art der Fläche" required>
                            <Select
                                value={areaType}
                                onChange={setAreaType}
                                options={[
                                    { value: "garden", label: "Garten" },
                                    { value: "balcony", label: "Balkon" },
                                    { value: "deck", label: "Dachterrasse" },
                                    { value: "interior", label: "Innenbereich" },
                                    { value: "other", label: "Sonstige" }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Untergrund" required>
                            <Select
                                value={subsoil}
                                onChange={setSubsoil}
                                options={[
                                    { value: "gardenTurf", label: "Garten-Grasnarbe" },
                                    { value: "roofBitumen", label: "Dach-Bitumen" },
                                    { value: "tiles", label: "Fliesen" },
                                    { value: "concrete", label: "Beton" },
                                    { value: "other", label: "Sonstige" }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>Installation</Divider>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Nutzung" required>
                            <Select
                                value={usage}
                                mode="multiple"
                                onChange={setUsage}
                                options={[
                                    { value: "optical", label: "Rein zur Optik" },
                                    { value: "children", label: "Kinder" },
                                    { value: "pets", label: "Haustiere" },
                                    { value: "sport", label: "Sportliche Aktivitäten" },
                                    { value: "exhibition", label: "Messe/Ausstellung" },
                                    { value: "balconyRoof", label: "Balkon/Dachterrasse" }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Zugang" required>
                            <Select
                                value={access}
                                onChange={setAccess}
                                options={[
                                    { value: "easy", label: "Einfach" },
                                    { value: "medium", label: "Mittel" },
                                    { value: "difficult", label: "Schwer" }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Installationshöhe" required>
                            <InputNumber
                                min={0}
                                disabled={subsoil === "gardenTurf"}
                                value={subsoil === "gardenTurf" ? null : installationHeight}
                                style={{ width: '100%' }}
                                addonAfter="Meter"
                                onChange={setInstallationHeight}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Produkt" required>
                            <Select
                                value={product}
                                onChange={setProduct}
                                options={[
                                    { value: "RASEN-3", label: "Die Ikone" },
                                    { value: "RASEN-4", label: "Der Charaktervolle" },
                                    { value: "RASEN-5", label: "Der Grüne" },
                                    { value: "RASEN-6", label: "Der Noble" },
                                    { value: "BIORASEN-12", label: "Die Ikone (BIO)" },
                                    { value: "BIORASEN-13", label: "Der Charaktervolle (BIO)" },
                                    { value: "BIORASEN-14", label: "Das Naturtalent (BIO)" },
                                    { value: "BIORASEN-15", label: "Der Noble (BIO)" },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>Bilder</Divider>
                <Upload
                    customRequest={async data => {
                        return new Promise(resolve => {
                            const reader = new FileReader();
                            reader.readAsDataURL(data.file);
                            reader.onload = async () => {
                                const base64 = reader.result.split(",")[1]

                                let result = null

                                try {
                                    result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/proposal/image`, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                                        },
                                        body: JSON.stringify({
                                            name: data.file.name,
                                            size: data.file.size,
                                            base64
                                        })
                                    })
                                } catch(e) {
                                    console.log(e)
                                    return data.onError(e)
                                }

                                const image = await result.json()

                                data.onSuccess()

                                setFileIds([ ...fileIds, { [image.id]: data.file.uid } ])

                                return resolve()
                            }
                        })
                    }}
                    accept="image/png,image/jpg,image/jpeg"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {fileList.length >= 15 ? null : uploadButton}
                </Upload>
                {previewImage && (
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
            </Form>
        </Modal>
    </Page>
}
