import { createIntl, EditableProTable, ProProvider } from "@ant-design/pro-components";
import { useContext, useEffect, useState } from 'react';
import {message} from "antd";

export default props => {
    const [ loading, setLoading ] = useState(true)
    const [ editableKeys, setEditableRowKeys ] = useState([]);
    const [ dataSource, setDataSource ] = useState([]);
    const [ randomKey, setRandomKey ] = useState(null)

    useEffect(() => {
        if(props.refresh) props.refresh(() => () => {
            setRandomKey(Math.random())
        })
    }, [])

    const enUSIntl = createIntl('en_US', {
        tableForm: {
            inputPlaceholder: "Wert eingeben",
            selectPlaceholder: "Wert auswählen"
        },
        table: {
            emptyText: "Keine Daten",
        },
        editableTable: {
            emptyText: "Keine Daten",
            action: {
                add: "Neuen Datensatz hinzufügen",
                delete: "Löschen",
                save: "Speichern",
                cancel: "Abbrechen"
            }
        }
    });

    const values = useContext(ProProvider)

    const [messageApi, messageContextHolder] = message.useMessage();

    return <>
        <ProProvider.Provider value={{
            ...values,
            intl: enUSIntl
        }}>
            <EditableProTable
                rowKey="id"
                headerTitle={props.title}
                locale={{ emptyText: 'Keine Daten' }}
                params={{
                    randomKey
                }}
                scroll={{
                    x: 960,
                }}
                recordCreatorProps={props.modes.includes("create") ? {
                    top: "bottom",
                    newRecordType: "dataSource",
                    record: () => ({ id: "null_" + (Math.random() * 1000000).toFixed(0) }),
                } : false}
                loading={loading}
                columns={[...props.columns, ...((props.modes.includes("edit") || props.modes.includes("delete")) ? [{
                    title: "Aktionen",
                    valueType: 'option',
                    key: "actions",
                    render: (text, record, _, action) => [
                        ...(props.modes.includes("edit") ? [<a
                            key="editable"
                            onClick={() => {
                                action?.startEditable?.(record.id);
                            }}>Bearbeiten</a>] : []),
                        ...(props.modes.includes("delete") ? [<a
                            key="delete"
                            onClick={async () => {
                                setLoading(true)

                                const res = await props.onDelete(record)

                                if(!res) messageApi.open({
                                    type: 'error',
                                    content: "Die Anfrage konnte nicht verarbeitet werden.",
                                });

                                const response = await props.request()

                                setLoading(false)

                                setDataSource(response.data)
                            }}>Löschen</a>] : [])
                    ],
                }] : [])]}
                request={async () => {
                    const data = await props.request()

                    setLoading(false)

                    return data
                }}
                value={dataSource}
                onChange={newData => {
                    setDataSource(newData)
                }}
                editable={{
                    type: 'single',
                    editableKeys,
                    deletePopconfirmMessage: "Datensatz löschen?",
                    onlyAddOneLineAlertMessage: "Sie können nur einen Datensatz gleichzeitig anlegen.",
                    onlyOneLineEditorAlertMessage: "Sie können nur einen Datensatz gleichzeitig bearbeiten.",
                    onSave: async (rowKey, data) => {
                        setLoading(true)

                        if(rowKey.startsWith("null_")) {
                            const res = await props.onCreate(data)

                            if(!res) messageApi.open({
                                type: 'error',
                                content: "Die Anfrage konnte nicht verarbeitet werden.",
                            });

                            const response = await props.request()

                            setLoading(false)

                            setTimeout(() => {
                                setDataSource(response.data)
                            }, 100)
                        } else {
                            const res = await props.onEdit(data)

                            if(!res) messageApi.open({
                                type: 'error',
                                content: "Die Anfrage konnte nicht verarbeitet werden.",
                            });

                            const response = await props.request()

                            setLoading(false)

                            setTimeout(() => {
                                setDataSource(response.data)
                            }, 100)
                        }
                    },
                    actionRender: (row, config, dom) => {
                        return <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
                            {Object.keys(dom).map(key => {
                                if(!dom[key]) return null
                                const Object = dom[key].type

                                if(key === "cancel" && row.id.startsWith("null_")) return null
                                if(key === "delete" && !row.id.startsWith("null_")) return null

                                if(key === "delete" && !props.modes.includes("delete")) return null

                                //if(key === "delete") return <Object key={key} {...dom[key].props} children={false} />

                                return <Object key={key} {...dom[key].props} />
                            })}
                        </div>
                    },
                    onChange: setEditableRowKeys
                }} />
        </ProProvider.Provider>
        {messageContextHolder}
    </>
}
