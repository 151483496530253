import Page from '../../components/Page';
import { Tag, Button, Modal } from 'antd';
import moment from "moment"
import Table from "../../components/Table"
import { useState } from "react";

export default () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ userId, setUserId ] = useState(null)
    const [ refresh, setRefresh ] = useState()

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
            title: "Registriert",
            key: "createdAt",
            dataIndex: "createdAt",
            readonly: true,
            render: val => moment(val).fromNow()
        },
        {
            title: "Vorname",
            key: "firstName",
            dataIndex: "firstName"
        },
        {
            title: "Nachname",
            key: "lastName",
            dataIndex: "lastName"
        },
        {
            title: "E-Mail",
            key: "email",
            dataIndex: "email",
            readonly: true
        },
        {
            title: "Rolle",
            key: "role",
            dataIndex: "role",
            valueType: "select",
            valueEnum: {
                sales: { text: 'Kundenberater', status: 'Default' },
                administrator: { text: 'Administrator', status: 'Warning' }
            },
            render: role => <Tag>{role}</Tag>
        },
        {
            title: "Anzahl Anmeldungen",
            readonly: true,
            key: "loginAmount",
            dataIndex: "loginAmount"
        },
        {
            title: "Letzte Anmeldung",
            readonly: true,
            key: "lastLoginAt",
            dataIndex: "lastLoginAt",
            render: val => {
                if(!moment(val).isValid()) return "Noch nie"

                return moment(val).fromNow()
            }
        },
        {
            title: "PLZ-Gebiete",
            readonly: true,
            key: "zipRegions",
            dataIndex: "zipRegions",
            render: (zipRegions, user) => {
                return <>{zipRegions} Stk. <a onClick={() => {
                    setUserId(user.id)
                    setIsModalOpen(true)
                }}>Bearbeiten</a></>
            }
        }
    ];

    return <Page title="Benutzer">
        <Table
            columns={columns}
            modes={[ "delete", "edit" ]}
            title="Benutzer im System (neuste zuerst)"
            refresh={setRefresh}
            onDelete={async user => {
                const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/user`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    },
                    body: JSON.stringify({
                        id: user.id
                    })
                })

                return result.status === 200
            }}
            onEdit={async user => {
                const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/user`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    },
                    body: JSON.stringify({
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        role: user.role
                    })
                })

                return result.status === 200
            }}
            request={async () => {
                const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/user/all`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    }
                })

                if(data.status !== 200) return ({
                    data: [],
                    total: 0,
                    success: false
                })

                const users = await data.json()

                return ({
                    data: users.map(user => ({ ...user, zipRegions: user.zipRegions.length, zipRegionsJSON: JSON.stringify(user.zipRegions) })),
                    total: users.length,
                    success: true
                })
            }}
        />
        <Modal width={720} title="PLZ-Gebiete bearbeiten" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            {userId && <Table
                key={userId}
                columns={[
                    {
                        title: "Start",
                        key: "start",
                        dataIndex: "start",
                        valueType: 'digit'
                    },
                    {
                        title: "Ende",
                        key: "end",
                        dataIndex: "end",
                        valueType: 'digit'
                    }
                ]}
                modes={[ "delete", "edit", "create" ]}
                onCreate={async zipRegion => {
                    const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/zipRegion`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                        },
                        body: JSON.stringify({
                            userId,
                            start: zipRegion.start,
                            end: zipRegion.end
                        })
                    })

                    refresh()

                    return result.status === 201
                }}
                onDelete={async zipRegion => {
                    const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/zipRegion`, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                        },
                        body: JSON.stringify({
                            id: zipRegion.id
                        })
                    })

                    refresh()

                    return result.status === 200
                }}
                onEdit={async zipRegion => {
                    const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/zipRegion`, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                        },
                        body: JSON.stringify({
                            id: zipRegion.id,
                            start: zipRegion.start,
                            end: zipRegion.end
                        })
                    })

                    return result.status === 200
                }}
                request={async () => {
                    const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/zipRegion/${userId}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                        }
                    })

                    if(data.status !== 200) return ({
                        data: [],
                        total: 0,
                        success: false
                    })

                    const regions = await data.json()

                    return ({
                        data: regions,
                        total: regions.length,
                        success: true,
                    })
                }}
            />}
        </Modal>
    </Page>
}
