import Page from '../../components/Page';
import {
    Image,
    Modal,
    Tag,
    Spin, Avatar, Popover, Button,
} from 'antd';
import moment from "moment"
import Table from "../../components/Table"
import { useState } from 'react';

export default () => {
    const [ isPictureModalOpen, setIsPictureModalOpen ] = useState(false)
    const [ imagePreviews, setImagePreviews ] = useState([])
    const [ refresh, setRefresh ] = useState()

    const [ imagePreviewsLoading, setImagePreviewsLoading ] = useState(false)

    return <Page title="Alle Kostenvoranschläge">
        <Table
            refresh={setRefresh}
            columns={[
                {
                    title: "Erstellt",
                    key: "createdAt",
                    dataIndex: "createdAt",
                    readonly: true,
                    render: (_, proposal) => {
                        return moment(proposal.createdAt).format("dddd, DD. MMM YYYY HH:mm")
                    }
                },
                {
                    title: "Status",
                    key: "status",
                    dataIndex: "status",
                    readonly: true,
                    render: (_, proposal) => {
                        return <Tag color={proposal.status === "waiting" ? "orange" : "green"}>
                            {proposal.status === "waiting" ? "Freigabe" : "Versendet"}
                        </Tag>
                    }
                },
                {
                    title: "Interessent",
                    key: "lead",
                    dataIndex: "lead",
                    readonly: true,
                    render: (_, { appointment }) => {
                        const { lead } = appointment

                        if(!lead) return <Tag>Keiner</Tag>

                        return <div>{lead.firstName} {lead.lastName} - {lead.email}</div>
                    }
                },
                {
                    title: "Kundenberater",
                    key: "user",
                    dataIndex: "user",
                    readonly: true,
                    render: (_, { appointment }) => {
                        const { lead } = appointment
                        const { user } = lead

                        if(!user) return <Tag>Keiner</Tag>

                        return <div style={{ display: "flex", alignItems: "center" }}><Avatar style={{ marginRight: 8, backgroundColor: "#2d4b00" }}>{user.firstName.substring(0, 1) + user.lastName.substring(0, 1)}</Avatar>{`${user.firstName} ${user.lastName}`}</div>
                    }
                },
                {
                    title: "Telefon",
                    key: "phone",
                    dataIndex: "phone",
                    render: (_, { phone, appointment }) => {
                        const { lead } = appointment

                        if(!lead) return <Tag>Keiner</Tag>

                        return <div>{phone || lead.phone}</div>
                    }
                },
                {
                    title: "Adresse",
                    key: "address",
                    dataIndex: "address",
                    readonly: true,
                    render: (_, { appointment }) => {
                        const { lead } = appointment

                        return <div>{lead.street}, {lead.zip} {lead.city}</div>
                    }
                },
                {
                    title: "Produkt",
                    key: "product",
                    dataIndex: "product",
                    valueType: "select",
                    valueEnum: {
                        "RASEN-3": { text: 'Die Ikone (42mm)' },
                        "RASEN-4": { text: 'Der Charaktervolle (45mm)' },
                        "RASEN-5": { text: 'Der Grüne (45mm)' },
                        "RASEN-6": { text: 'Der Noble (50mm)' },
                        "BIORASEN-12": { text: 'Die Ikone - pflanzenbasiert (40mm)' },
                        "BIORASEN-13": { text: 'Der Charaktervolle - pflanzenbasiert (50mm)' },
                        "BIORASEN-14": { text: 'Das Naturtalent - pflanzenbasiert (40mm)' },
                        "BIORASEN-15": { text: 'Der Noble - pflanzenbasiert (50mm)' },
                    }
                },
                {
                    title: "Flächenform",
                    key: "areaForm",
                    dataIndex: "areaForm",
                    valueType: "select",
                    valueEnum: {
                        "rectangle": { text: 'Rechteck' },
                        "lshape": { text: 'L-Form' },
                        "circle": { text: 'Kreis' }
                    }
                },
                {
                    title: "Art der Fläche",
                    key: "areaType",
                    dataIndex: "areaType",
                    valueType: "select",
                    valueEnum: {
                        "garden": { text: 'Garten' },
                        "balcony": { text: 'Balkon' },
                        "deck": { text: 'Dachterrasse' },
                        "interior": { text: 'Innenbereich' },
                        "other": { text: 'Sonstige' }
                    }
                },
                {
                    title: "Untergrund",
                    key: "subsoil",
                    dataIndex: "subsoil",
                    valueType: "select",
                    valueEnum: {
                        "gardenTurf": { text: 'Garten-Grasnarbe' },
                        "roofBitumen": { text: 'Dach-Bitumen' },
                        "tiles": { text: 'Fliesen' },
                        "concrete": { text: 'Beton' },
                        "other": { text: 'Sonstige' }
                    }
                },
                {
                    title: "Zugang",
                    key: "access",
                    dataIndex: "access",
                    valueType: "select",
                    valueEnum: {
                        "easy": { text: 'Einfach' },
                        "medium": { text: 'Mittel' },
                        "difficult": { text: 'Schwer' }
                    }
                },
                {
                    title: "Länge",
                    valueType: "digit",
                    key: "depth",
                    dataIndex: "depth",
                    render: (_, { depth }) => {
                        return <div>{depth} m²</div>
                    }
                },
                {
                    title: "Breite",
                    valueType: "digit",
                    key: "width",
                    dataIndex: "width",
                    render: (_, { width }) => {
                        return <div>{width} m²</div>
                    }
                },
                {
                    title: "Fläche",
                    valueType: "digit",
                    key: "size",
                    dataIndex: "size",
                    render: (_, { areaForm, size, width, depth }) => {
                        return <div>{areaForm === "rectangle" ? (width * depth) : size} m²</div>
                    }
                },
                {
                    title: "KVA",
                    key: "actions",
                    dataIndex: "actions",
                    readonly: true,
                    render: (_, proposal) => {
                        return <div style={{ display: "flex", gap: 16, flexWrap: "wrap" }}>
                            <a onClick={() => {
                                window.open(`${global.BesserRasen.backendUrl}/api/v1/proposal/pdf/${proposal.id}`, "_blank")
                            }}>PDF anzeigen</a>
                            {proposal.images.length > 0 && <a onClick={async () => {
                                setIsPictureModalOpen(true)
                                setImagePreviews([])
                                setImagePreviewsLoading(true)

                                Promise.all(proposal.images.map(async image => {
                                    return new Promise(async resolve => {
                                        const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/proposal/image/${image.id}`, {
                                            method: "GET",
                                            headers: {
                                                "Authorization": `Bearer ${global.BesserRasen.session.token}`
                                            }
                                        })

                                        const blob = await data.blob()

                                        const reader = new FileReader()

                                        reader.onload = function() {
                                            resolve(this.result)
                                        }

                                        reader.readAsDataURL(blob)
                                    })
                                })).then(images => {
                                    setImagePreviewsLoading(false)
                                    setImagePreviews(images)
                                })
                            }}>Bilder anzeigen ({proposal.images.length})</a>}
                            {proposal.status === "waiting" && <Popover title="KVA versenden?" trigger="click" content={<div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                                Diese Aktion ist nicht widerrufbar.
                                <Button type="primary" onClick={async () => {
                                    await fetch(`${global.BesserRasen.backendUrl}/api/v1/proposal/send`, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Authorization": `Bearer ${global.BesserRasen.session.token}`
                                        },
                                        body: JSON.stringify({
                                            id: proposal.id
                                        })
                                    })

                                    refresh()
                                }}>KVA versenden</Button>
                            </div>}>
                                <a>Versenden</a>
                            </Popover>}
                        </div>
                    }
                }
            ]}
            modes={[ "edit" ]}
            title="Alle Kostenvoranschläge (neuste zuerst)"
            onEdit={async proposal => {
                const result = await fetch(`${global.BesserRasen.backendUrl}/api/v1/proposal`, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    },
                    body: JSON.stringify(proposal)
                })

                return result.status === 200
            }}
            request={async () => {
                const data = await fetch(`${global.BesserRasen.backendUrl}/api/v1/proposal/all`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${global.BesserRasen.session.token}`
                    }
                })

                if(data.status !== 200) return ({
                    data: [],
                    total: 0,
                    success: false
                })

                const proposals = await data.json()

                return ({
                    data: proposals.sort((a, b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()),
                    total: proposals.length,
                    success: true,
                })
            }}
        />
        <Modal width={720} title="Bilder anzeigen" open={isPictureModalOpen} onCancel={() => setIsPictureModalOpen(false)} footer={null}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: 16 }}>
                {imagePreviews.map((imageUrl, index) => <Image key={index} src={imageUrl} height={150} />)}
            </div>
            {imagePreviewsLoading && <div style={{ width: "100%", height: 150, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Spin />
            </div>}
        </Modal>
    </Page>
}
